<template>
    <div>
      <div class="flex-row justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>
            <el-button size="mini" @click="handleBack">上一步</el-button>
          </div>
          <div class="margin-left-30">
            <span class="color-sub">预测目标：</span>
            <el-popover placement="right-start" width="350" trigger="hover" v-if="currentTarget">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{currentTarget.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{currentTarget.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{currentTarget.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{currentTarget.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{currentTarget.child.startDate}}{{currentTarget.child.startDate && currentTarget.child.endDate?"至":""}}{{currentTarget.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{currentTarget.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{currentTarget.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                    <span class="link">{{currentTarget.targetNameAll}}</span>
                </span>
              </el-popover>  
          </div>
        </div>
        <div class="flex-row align-items-center">
          <div>
            <span class="color-sub">因子数量：</span><span :class="{'color-red':modelInfo.divisors.length>maxNum}">{{modelInfo.divisors.length}}/{{maxNum}}</span>
          </div>
          <div class="border-right margin-left-30 padding-right-10">
            <el-button size="mini" type="primary" :loading="runLoading" @click="getDagRunId">计算影响度</el-button>
          </div>
          <div class="margin-left-10">
            <el-button size="mini" type="primary" :disabled="!influenceValues" :loading="creatModelLoading" @click="modelInfo.creatInfo?handleNext():creatModel()">下一步</el-button>
          </div>
        </div>
      </div>

      <div>
        <el-table
          :height="$root.docHeight-295"
          :data="modelInfo.divisors"
          size="medium"
          style="width: 100%">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column label="因子名称" min-width="200">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{props.row.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{props.row.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{props.row.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{props.row.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{props.row.child.startDate}}{{props.row.child.startDate&&props.row.child.endDate?"至":""}}{{props.row.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{props.row.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{props.row.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                  <div class="flex-row align-items-center">
                    <div :class="{'link':true,'color-red':modelInfo.divisors.filter(v=>v.dataId==props.row.dataId).length>1&&modelInfo.divisors.filter(v=>v.dataId==props.row.dataId).findIndex(v=>v.customDivisorId==props.row.customDivisorId)>0}">{{props.row.targetNameAll}}</div>
                    <div v-if="props.row.dataId==currentTarget.dataId||modelInfo.divisors.filter(v=>v.dataId==props.row.dataId).length>1&&modelInfo.divisors.filter(v=>v.dataId==props.row.dataId).findIndex(v=>v.customDivisorId==props.row.customDivisorId)>0" class="custom-tag margin-left-5" color="red">重复</div>
                  </div>
                </span>
              </el-popover>  
             
            </template>
          </el-table-column>
          <el-table-column label="单位">
            <template slot-scope="props">{{props.row.unit}}</template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="props">{{props.row.typeName}}</template>
          </el-table-column>
          <el-table-column label="数据周期" width="200">
            <template slot-scope="props">
                <div>{{props.row.startDate}} {{props.row.startDate&&props.row.endDate?"至":""}} {{props.row.endDate}}</div>
            </template>
          </el-table-column>
          <el-table-column label="影响度" :sortable="influenceValues?true:false" prop="influenceValues">
            <template slot-scope="props">
              <i class="el-icon-star-on" v-for="item in props.row.influenceValues" :key="item"></i>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="props">
              <span class="link-text" @click="removeDivisor(props.row)">剔除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div> 
</template>

<script>
export default {
  name: 'Divisorselect',
  components: {  },
  props:{
    divisors:{
      type:Array,
      default:()=>{
        return []
      }
    },
    modelFreq:{
      type:String,
      default:'week'
    },
    currentTarget:{
      type:Object,
      default:()=>{
        return null
      }
    }
  },
  data() {
    return {
      maxNum:20,
      creatModelLoading:false,
      runLoading:false,
      dagRunId:'',
      runTimer:null,
      influenceValues:null,//影响度结果
      modelInfo:{
        divisors:[],
        creatInfo:null,
      }
    }
  },
  computed:{
    hisCycle(){
      let startArr=this.modelInfo.divisors.map(v=>{return new Date(v.startDate).getTime()})
      let start=startArr.length?Math.max.apply(null, startArr):''
      return start?new Date(start).format("yyyy-MM-dd"):""
    },
    isDivisorsRepeat(){
      let isRepeat=false
      for(let item of this.modelInfo.divisors){
        if(item.dataId==this.currentTarget.dataId||this.modelInfo.divisors.filter(v=>v.dataId==item.dataId).length>1){
          isRepeat=true
          break;
        }
      }
      return isRepeat
    }
  },
  created(){

  },
  destroyed(){
   this.killDag()
  },
  methods:{
    init(){
      this.runLoading=false
      this.influenceValues=null
      this.modelInfo.divisors=JSON.parse(JSON.stringify(this.divisors))
      this.modelInfo.creatInfo=null
     
    },
    removeDivisor(row){
      let index=this.modelInfo.divisors.findIndex(v=>v.dataId==row.dataId)
      this.modelInfo.divisors.splice(index,1)
      this.modelInfo.creatInfo=null
    },
    getDagRunId(){
      if(this.isDivisorsRepeat){
        this.$message({message:'请剔除重复因子后计算影响度',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      if(this.modelInfo.divisors.length<2){
        this.$message({message:'需2个以上可用因子参与运算',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.runLoading=true
      let requestData={
        modelFreq:this.modelFreq,
        divisorIds:this.modelInfo.divisors.map(v=>v.customDivisorId),
        targetId:this.currentTarget.targetId,
      }
      this.$instance.post('/befarCustomModel/calcInfluence',requestData)
      .then(res=>{
        if(res.data.code==0){
          this.dagRunId=res.data.info.dagRunId
          this.runStar()
        }
      })
      .catch(()=>{
        this.runLoading=false
      })
    },
    runStar(){
      this.$instance.get('/befarCustomModel/getInfluence',{
        params: {
          ...this.$store.state.basicParams,
          dagRunId:this.dagRunId,
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.runLoading=false
          this.influenceValues=res.data.info.influenceValues
         
          for(let item of this.influenceValues){
            let index=this.modelInfo.divisors.findIndex(v=>v.customDivisorId==item.customDivisorId)
            if(index>=0){
              this.$set(this.modelInfo.divisors[index],'influenceValues',item.value)
            }
          }
        }else if(res.data.code==1){
          this.$message.closeAll()
          this.runTimer=setTimeout(()=>{this.runStar()},3000)
        }else{
          this.runLoading=false
        }
      })
      .catch(()=>{
        this.runLoading=false
      })
    },
    creatModel(){
      if(this.modelInfo.divisors.length<2){
        this.$message({message:'需2个以上可用因子参与运算。',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }else if(this.modelInfo.divisors.length>this.maxNum){
        this.$message({message:'您用于模型运算的因子过多，请剔除或更换因子。',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.creatModelLoading=true
      let requestData= {
        modelId:null,
        predictResult:null,
        agriName:this.modelFreq=='week'?'ARIMAX模型':'BVAR模型',
        featureMode:this.modelFreq=='week'?'周均值':'月均值',
        samplingNum:this.modelFreq=='week'?null:30000,
        frequency:this.modelFreq=='week'?4:12,
        hisCycle:this.hisCycle,
        productId:this.currentTarget.productId,
        modelFreq:this.modelFreq,
        divisorIds:this.modelInfo.divisors.map(v=>v.customDivisorId),
        targetId:this.currentTarget.targetId
      }
      this.$instance.post('/befarCustomModel/createModel',requestData)
      .then(res=>{
        this.creatModelLoading=false
        if(res.data.code==0){
          this.modelInfo.creatInfo=res.data.info.customModel
          this.killDag()
          this.$emit('next',this.modelInfo)
        }
      })
      .catch(()=>{
        this.creatModelLoading=false
      })
    },
    //中止运算
    killDag(){
      this.runLoading&&this.dagRunId&&this.cancelOperation()
      this.runLoading&&clearTimeout(this.runTimer)
    },
    cancelOperation(){
      this.$instance.get('/befarCustomModel/killDag',{
          params: {
            ...this.$store.state.basicParams,
            dagRunId:this.dagRunId,
            dagId:'befar_pf_influence_divisor_single'
          },
      })
      .then(res=>{
          if(res.data.code==0){
            clearTimeout(this.runTimer)
            this.runLoading=false
          }
      })
      .catch(()=> {}); 
    },
    handleNext(){
      if(this.modelInfo.divisors.length<2){
        this.$message({message:'需2个以上可用因子参与运算。',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }else if(this.modelInfo.divisors.length>this.maxNum){
        this.$message({message:'您用于模型运算的因子过多，请剔除或更换因子。',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }

      this.killDag()
      this.$emit('next',this.modelInfo)
    },
    handleBack(){
      this.killDag()
      this.$emit('back',this.modelInfo)
    },
    
  },
  watch: {
    'divisors':{
      handler:function(){
        console.log('RelaValidation')
        this.init()
      },
      deep:true,
    },
    'modelFreq':{
      handler:function(){
        this.killDag()
      },
      deep:true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
