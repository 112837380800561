<template>
    <div>
      <div class="flex-row justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>
            <el-button size="mini" @click="handleBack">上一步</el-button>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <div class="border-right margin-left-30 padding-right-10">
            <el-button size="mini" type="primary" :loading="getRunIdLoading" @click="getRunId">运算</el-button>
          </div>
          <div class="margin-left-10">
            <el-button size="mini" type="primary" :disabled="!result.forecast" @click="handleNext">下一步</el-button>
          </div>
        </div>
      </div>

      <div class="flex-row" :style="{'height':`${$root.docHeight-295}px`,'min-height':'400px'}" v-if="creatInfo">
        <div style="flex:0 0 300px" class="flex-column border-right padding-top-20 padding-right-20">
          <div class="border-bottom padding-bottom-20">
            <div class="color-sub">预测目标：</div>
            <div class="margin-top-10">
              <el-popover placement="right-start" width="350" trigger="hover" v-if="currentTarget">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{currentTarget.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{currentTarget.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{currentTarget.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{currentTarget.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{currentTarget.child.startDate}}{{currentTarget.child.startDate && currentTarget.child.endDate?"至":""}}{{currentTarget.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{currentTarget.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{currentTarget.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                    <span class="link">{{currentTarget.targetNameAll}}</span>
                </span>
              </el-popover>    
            </div>
          </div>
         
          <div class="color-sub margin-top-20">因子：</div>
          <div class="border-bottom padding-bottom-20 flex-auto" style="overflow-y: auto; overflow-x: hidden;">
            <div v-for="(item,index) in divisors" :key="index" class="text-overflow-ellipsis margin-top-10" style="width: 280px;">
              
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{item.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{item.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{item.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{item.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{item.child.startDate}}{{item.child.startDate&&item.child.endDate?"至":""}}{{item.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{item.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{item.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                  <span class="link">{{item.targetNameAll}}</span>
                </span>
              </el-popover>  
            </div>
          </div>
          <div>
            <div class="margin-top-20">
              <span class="color-sub">模型设置：</span>
            </div>
            <div class="margin-top-10">
              <span class="color-sub">模型框架：</span>
              {{creatInfo.agriName}}
            </div>
            <div class="margin-top-10">
              <span class="color-sub">预测周期：</span>
              {{creatInfo.frequency}}期
            </div>
            <div class="margin-top-10" v-if="creatInfo.samplingNum">
              <span class="color-sub">模拟次数：</span>
              {{creatInfo.samplingNum}}
            </div>
            <div class="margin-top-10">
              <span class="color-sub">历史估计周期：</span>
              {{creatInfo.hisCycle | dateFormat('yyyy-MM-dd')}}至今
            </div>
            <div class="margin-top-10">
              <span class="color-sub">特征模式：</span>
              {{creatInfo.featureMode}}
            </div>
          </div>
        </div>
        <div class="flex-column flex-auto padding-top-20 padding-left-20">
          <div>
            <div class="column-tabs">
              <div :class="{'active':chartType==1}" @click="chartType=1">预测结果</div>
              <div :class="{'active':chartType==2}" @click="chartType=2">模型精度</div>
            </div>
            <div class="margin-top-10 font-size-12 color-sub" v-if="chartType==1">
                预测周期：<template v-if="result.forecast">{{result.forecast.startTime | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{result.forecast.endTime | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
            </div>
            <div class="margin-top-10 font-size-12 color-sub" v-else>
                验证周期：<template v-if="result.accuracy">{{result.accuracy.startTime | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{result.accuracy.endTime | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
            </div>
          </div>
          <template v-if="chartType==1">
            <Chart :option="forecastChart" class="flex-auto margin-tb-20" height="100px"></Chart>
          </template>
          <template v-else>
            <div class="flex-row margin-top-20" v-if="result.accuracy">
              <div style="margin-right:5%">
                <p class="color-sub">移动1期平均偏差率</p>
                <p class="font-size-20">{{result.accuracy.step1DeviationRateAvg | numFormat(2,'-')}}%</p>
              </div>
            </div>
            <Chart :option="verifyRateChart" class="flex-auto margin-tb-20" height="100px"></Chart>
          </template>
          <div class="flex-row justify-center border-top padding-top-20">
            <el-button size="mini" type="primary" :loading="save.loading" :disabled="!result.forecast" @click="checkSave">保存</el-button>
          </div>
        </div>
      </div>
      <el-dialog custom-class="custom-dialog" title="保存我的模型" :visible.sync="save.visible" width="400px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="35vh">
          <div>
            <el-input v-model.trim="save.title" maxlength="30" show-word-limit placeholder="请为您的模型命名"></el-input>
          </div>
          <div class="flex-row justify-center margin-top-20">
            <el-button size="mini" @click="save.visible=false">取消</el-button>
            <el-button size="mini" type="primary" :loading="save.loading" @click="saveModel(true)">保存</el-button>
          </div>

        </el-dialog>
        <el-dialog
          custom-class="custom-dialog"
          :append-to-body="true"
          top="30vh"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="progress.visible"
          width="40%"
          >
          <div slot="title">
            <div class="font-size-20">模型正在计算，预计需要{{(Math.round(progress.totalTime/60)||1)}}分钟，请稍候...</div>
            <div class="color-sub margin-top-10">温馨提示：刷新或关闭页面会中止运算，并将页面初始化。</div>
          </div>
          <Progress :totalSeconds="progress.totalTime" :finished="progress.finished" v-if="progress.visible" @cancel="cancelOperation"></Progress>
        </el-dialog>
    </div> 
</template>

<script>
import Chart from "@/components/Chart";
import Progress from "@/components/Progress";
import progressTotalTime from "@/common/progressTotalTime";//进度条时间计算方法
export default {
  name: 'ModelOperation',
  components: { Chart,Progress },
  props:{
    creatInfo:{
      type:Object,
      default:()=>{
        return null
      }
    },
    divisors:{
      type:Array,
      default:()=>{
        return []
      }
    },
    modelFreq:{
      type:String,
      default:'week'
    },
    currentTarget:{
      type:Object,
      default:()=>{
        return null
      }
    }
  },
  data() {
    return {
      runTimer:null,//运算轮巡定时器
      chartType:1,//1预测结果，2模型精度
      getRunIdLoading:false,
      dagRunId:null,
      publishId:null,
      result:{//运算结果
        forecast:null,
        accuracy:null
      },
      save:{//保存模型弹窗
        visible:false,
        loading:false,
        title:''
      },
      progress:{
        visible:false,
        finished:true,
        totalTime:60,
      },
      successInfo:{
        publishId:null,
        result:null
      }
      
    }
  },
  computed:{
    //预测结果图表
    forecastChart(){
      let chartData=this.result.forecast||{}
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=chartData.items[0].unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //模型精度图表
    verifyRateChart(){
      let chartData=this.result.accuracy||{}
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          let isLine=(v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            // barGap:'-20%',
            itemStyle:{ 
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
  },
  created(){

  },
  destroyed(){
   this.killDag()
  },
  methods:{
    init(){
      this.chartType=1
      this.result={
        forecast:null,
        accuracy:null
      }
      this.sucessInfo={
        publishId:null,
        result:null
      }
    },
    getRunId(){
      this.getRunIdLoading=true
      let requestData={
        modelFreq:this.modelFreq,
        modelId:this.creatInfo.customModelId,
      }
      this.$instance.post('/befarCustomModel/calcModel',requestData)
      .then(res=>{
        this.getRunIdLoading=false
        if(res.data.code==0){
          this.progress.totalTime=progressTotalTime(this.creatInfo.samplingNum,this.divisors.length+1)
          this.progress.finished=false
          this.progress.visible=true
          this.dagRunId=res.data.info.dagRunId
          this.publishId=res.data.info.publishId
          this.runModel()
        }
      })
      .catch(()=>{
        this.getRunIdLoading=false
      })
    },
    runModel(){
      this.$instance.get('/befarCustomModel/getTargetCustomValue',{
        params: {
          ...this.$store.state.basicParams,
          dagRunId:this.dagRunId,
          modelFreq:this.modelFreq,
          modelId:this.creatInfo.customModelId,
          publishId:this.publishId,
          type:'uncond'
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.result.forecast=res.data.info
          this.successInfo={
            publishId:this.publishId,
            result:res.data.info
          }
          this.progress.finished=true
          this.getModelAccuracy()
          setTimeout(()=>{this.progress.visible=false},1000)

        }else if(res.data.code==1){
          console.log(1)
          this.$message.closeAll()
          this.runTimer=setTimeout(()=>{this.runModel()},3000)
        }else{
          this.progress.finished=true
          this.progress.visible=false
        }
      })
      .catch(()=>{
        this.progress.finished=true
          this.progress.visible=false
      })
    },
    //获取模型精度
    getModelAccuracy(){
      this.$instance.get('/befarCustomModel/getModelAccuracy',{
        params: {
          ...this.$store.state.basicParams,
          modelFreq:this.modelFreq,
          modelId:this.creatInfo.customModelId,
          publishId:this.successInfo.publishId,
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.result.accuracy=res.data.info
        }
      })
    },
    //中止运算
    killDag(){
      !this.progress.finished&&this.dagRunId&&this.cancelOperation()
      !this.progress.finished&&clearTimeout(this.runTimer)
    },
    cancelOperation(){
      this.$instance.get('/befarCustomModel/killDag',{
          params: {
            ...this.$store.state.basicParams,
            dagRunId:this.dagRunId,
            dagId:this.modelFreq=='week'?'befar_pf_custom_assess_week':'befar_pf_custom_assess_month'
          },
      })
      .then(res=>{
          if(res.data.code==0){
            this.progress.finished=true
            this.progress.visible=false;
            clearTimeout(this.runTimer)
          }
      })
      .catch(()=> {}); 
    },
    //保存模型
    checkSave(){
      this.save.title=''
      this.save.loading=true
      this.$instance.get('/befarCustomModel/checkExistModel',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.creatInfo.customModelId,
          },
      })
      .then(res=>{
        this.save.loading=false
        if(res.data.code==0){
          if(res.data.info){
            this.saveModel()
          }else{
            this.save.visible=true
          }
        }
      })
      .catch(()=>{
        this.save.loading=false
      })
    },
    saveModel(checkTitle){
      if(checkTitle&&!this.save.title){
        this.$message({message:'请输入模型名称',type:'warning',center:true})
        return false
      }
      this.save.loading=true
      this.$instance.get('/befarCustomModel/modifyModelName',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.creatInfo.customModelId,
           modelName:this.save.title,
           publishId:this.successInfo.publishId,
          },
      })
      .then(res=>{
        this.save.loading=false
        if(res.data.code==0){
          this.save.visible=false
          this.$message({message:'保存成功，请前往“结果查看”中查看',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=>{
        this.save.loading=false
      })
    },
    
    handleNext(){
      this.$emit('next',this.successInfo)
    },
    handleBack(){
      this.$emit('back',null)
    }
  },
  watch: {
    'creatInfo':{
      handler:function(){
        console.log('modelOperation')
        this.init()
      },
      deep:true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
