<template>
    <div v-loading="divisors.loading">
      <div class="flex-row justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>
            <el-button size="mini" @click="handleBack">上一步</el-button>
          </div>
          <div class="margin-left-30">
            <span class="color-sub">预测目标：</span>
            <el-popover placement="right-start" width="350" trigger="hover" v-if="currentTarget">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{currentTarget.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{currentTarget.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{currentTarget.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{currentTarget.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{currentTarget.child.startDate}}{{currentTarget.child.startDate && currentTarget.child.endDate?"至":""}}{{currentTarget.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{currentTarget.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{currentTarget.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                    <span class="link">{{currentTarget.targetNameAll}}</span>
                </span>
              </el-popover>  
          </div>
          <div class="margin-left-30">
            <el-input v-model.trim="divisors.keyWord" size="mini" prefix-icon="el-icon-search" placeholder="请输入关键字" clearable></el-input>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <div>
            <span class="color-sub">已选因子：</span>{{divisors.multipleSelection.length}}/{{maxNum}}
          </div>
          <div class="border-right margin-left-30 padding-right-10">
            <el-button size="mini" @click="addDivisorsShow">添加因子</el-button>
          </div>
          <div class="margin-left-10">
            <el-button size="mini" type="primary" @click="handleNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="flex-row border-bottom padding-top-15 padding-bottom-5 text-nowrap">
        <div class="color-sub">选择产品：</div>
        <div class="flex-row flex-wrap">
          <div class="margin-lr-10 hover-opacity" @click="selectProduct()"><span :class="{'color-blue bold':!divisors.curProdIds.length}">全部</span></div>
          <div class="margin-bottom-10 margin-lr-10 hover-opacity" v-for="(item,index) in productList" :key="index" @click="selectProduct(item.productId)">
            <span :class="{'color-blue bold':divisors.curProdIds.indexOf(item.productId)>=0}">{{ item.productName }}</span>
          </div>
        </div>
      </div>
      <div>
        <el-table
          ref="divisors"
          :height="$root.docHeight-342"
          :data="divisorsOfFilter"
          size="medium"
          :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
          @selection-change="handleDivisorsSelection"
          row-key="customDivisorId"
          style="width: 100%">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column type="selection" :reserve-selection="true" :selectable="selectableFun" width="50"></el-table-column>
          <el-table-column label="因子名称" min-width="200">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{props.row.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{props.row.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{props.row.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{props.row.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{props.row.child.startDate}}{{props.row.child.startDate&&props.row.child.endDate?"至":""}}{{props.row.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{props.row.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{props.row.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                    <span class="link">{{props.row.targetNameAll}}</span>
                </span>
              </el-popover>  
            </template>
          </el-table-column>
          <el-table-column label="单位">
            <template slot-scope="props">{{props.row.unit}}</template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="props">{{props.row.typeName}}</template>
          </el-table-column>
          <el-table-column label="数据周期" width="270">
            <template slot-scope="props">
              <div class="flex-row align-items-center">
                <div :class="{'color-red':!props.row.dataFlag}">{{props.row.startDate}} {{props.row.startDate&&props.row.endDate?"至":""}} {{props.row.endDate}}</div>
                <div v-if="!props.row.dataFlag" class="custom-tag margin-left-5" color="red">数据不足</div>
              </div>  
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog custom-class="custom-dialog" :visible.sync="addDivisors.visible" width="80%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="50px">
          <div slot="title">
            <div class="flex-row align-items-center justify-between margin-right-40">
              <div class="el-dialog__title">添加因子</div>
            </div>
          </div>
          <div  v-loading="addDivisors.loading">
            <div class="flex-row justify-between border-bottom padding-bottom-10">
              <div class="flex-row">
                <div>
                  <el-select v-model="addDivisors.curProdId" size="mini" @change="changeAddDivisorsProd">
                    <el-option v-for="(item,index) in productList" :key="index" :value="item.productId" :label="item.productName"></el-option>
                  </el-select>
                </div>
                <div class="margin-left-10">
                  <el-input v-model="addDivisors.keyWord" size="mini" prefix-icon="el-icon-search" placeholder="请输入关键字" clearable></el-input>
                </div>
              </div>
              <div>数据量：{{addTargetsOfFilter.length}}</div>
            </div>
            <el-table
              :height="$root.docHeight-330"
              :data="addTargetsOfFilter"
              size="medium"
              :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
              @selection-change="handleAddDivisorsSelection"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column label="待选数据" min-width="200">
                <template slot-scope="props">
                  <el-popover placement="right-start" width="350" trigger="hover">
                    <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                      <div><span class="color-sub">数据名称：</span>{{props.row.child.factorName}}</div>
                      <div><span class="color-sub">数据单位：</span>{{props.row.child.unit}}</div>
                      <div><span class="color-sub">数据类型：</span>{{props.row.child.typeName}}</div>
                      <div><span class="color-sub">更新频率：</span>{{props.row.child.modelFreq}}</div>
                      <div><span class="color-sub">统计日期：</span>{{props.row.child.startDate}}{{props.row.child.startDate&&props.row.child.endDate?"至":""}}{{props.row.child.endDate}}</div>
                      <div><span class="color-sub">方法论：</span>{{props.row.child.func}}</div>
                      <div><span class="color-sub">数据来源：</span>{{props.row.child.dataSource}}</div>

                    </div>
                    <span slot="reference" class="display-inline-block">
                        <span class="link">{{props.row.targetNameAll}}</span>
                    </span>
                  </el-popover>  
                </template>
              </el-table-column>
              <el-table-column label="类型" width="200">
                <template slot-scope="props">{{props.row.typeName}}</template>
              </el-table-column>
              <el-table-column label="数据周期" width="200">
                <template slot-scope="props">{{props.row.startDate}} {{props.row.startDate&&props.row.endDate?"至":""}} {{props.row.endDate}}</template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <div class="flex-row justify-end">
              <el-button size="small" @click="addDivisors.visible=false">取消</el-button>
              <el-button size="" type="primary" :loading="addDivisors.loading" @click="addDivisorsSubmit">确定</el-button>
            </div>
          </div>
        </el-dialog>
    </div> 
</template>

<script>
export default {
  name: 'Divisorselect',
  components: {  },
  props:{
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    modelFreq:{
      type:String,
      default:'week'
    },
    currentTarget:{
      type:Object,
      default:()=>{
        return null
      }
    }
  },
  data() {
    return {
      maxNum:30,
      divisors:{
        loading:false,
        list:[],
        curProdIds:[],
        keyWord:'',
        multipleSelection:[],
      },
      addDivisors:{
        loading:false,
        visible:false,
        list:[],
        curProdId:'',
        keyWord:'',
        multipleSelection:[],
        
      }
    }
  },
  computed:{
    divisorsOfFilter(){
      return this.divisors.list.filter(v=>{return v.targetNameAll.indexOf(this.divisors.keyWord)>=0})
    },
    addTargetsOfFilter(){
      return this.addDivisors.list.filter(v=>{return v.targetNameAll.indexOf(this.addDivisors.keyWord)>=0})
    }
  },
  created(){
    this.getData()
   
  },
  methods:{
    init(){
      this.divisors.curProdIds=this.currentTarget?[this.currentTarget.productId]:[]
      this.divisors.keyWord=''
     this.$refs.divisors.clearSelection()
      this.getData()
    },
    getData(){
      if(this.productList.length&&this.currentTarget){
        this.getDivisorsList()
      }
    },
    //选择产品
    selectProduct(id){
      if(id){
        let index=this.divisors.curProdIds.indexOf(id)
        if(index>=0){
          this.divisors.curProdIds.splice(index,1)
        }else{
          this.divisors.curProdIds.push(id)
        }
      }else{
        this.divisors.curProdIds=[]
      }
      this.getDivisorsList()
    },
     //获取因子列表
     getDivisorsList(){
      this.divisors.loading=true
      this.$instance.get('/befarCustomModel/getDivisorList',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           productIds:this.divisors.curProdIds.join(',')
          },
      })
      .then(res=>{
        this.divisors.loading=false
        if(res.data.code==0){
          this.divisors.list=res.data.info
        }else{
          this.divisors.list=[]
        }
      })
      .catch(()=>{
        this.divisors.loading=false
        this.divisors.list=[]
      })
    },
    //列表行是否可选
    selectableFun(row){
      return row.dataFlag
    },
    //勾选列表
    handleDivisorsSelection(val) {
      this.divisors.multipleSelection = val;
      if(val.length>this.maxNum){
        this.$refs.divisors.toggleRowSelection(val[val.length-1],false);
        this.$message({message: `最多选择${this.maxNum}个因子`,type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
    },
    //添加目标弹窗显示
    addDivisorsShow(){
      this.addDivisors.visible=true
      !this.addDivisors.curProdId&&(this.addDivisors.curProdId=(this.productList[0]||{}).productId)
      this.addDivisors.keyWord=''
      this.addDivisors.curProdId&&this.getAddDivisorsList()
    },
    //添加因子列表切换产品
    changeAddDivisorsProd(){
      this.addDivisors.keyWord=''
      this.getAddDivisorsList()
    },
    //获取添加因子列表
    getAddDivisorsList(){
      this.addDivisors.loading=true
      this.$instance.get('/befarCustomModel/getAddDivisorList',{
          params: {
           ...this.$store.state.basicParams,
           productId:this.addDivisors.curProdId,
           targetId:this.currentTarget.targetId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.addDivisors.loading=false
        if(res.data.code==0){
          this.addDivisors.list=res.data.info
        }else{
          this.addDivisors.list=[]
        }
      })
      .catch(()=>{
        this.addDivisors.loading=false
        this.addDivisors.list=[]
      })
    },
    //勾选添加因子列表
    handleAddDivisorsSelection(val) {
      this.addDivisors.multipleSelection = val;
    },
    addDivisorsSubmit(){
      if(!this.addDivisors.multipleSelection.length){
        this.$message({message:'请选择要添加的因子',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      this.addDivisors.loading=true
      let requestData=this.addDivisors.multipleSelection.map(v=>{return {
        modelFreq:this.modelFreq,
        productId:this.addDivisors.curProdId,
        isTarget:v.isTarget,
        dataId:v.dataId,
        targetNameAll:v.targetNameAll,
        unit:v.unit,
        marketAttr:v.marketAttr,
        dataModel:v.dataModel,
        type:v.type,
        startDate:v.startDate,
        endDate:v.endDate,
        typeId:v.typeId||'',
      }})
      this.$instance.post('/befarCustomModel/addTarget',requestData)
      .then(res=>{
        this.addDivisors.loading=false
        if(res.data.code==0){
          this.getData()
          this.addDivisors.visible=false;
          this.$message({message:'添加成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=>{
        this.addDivisors.loading=false
      })
    },
    handleNext(){
      if(this.divisors.multipleSelection.length<2){
        this.$message({message:'请选择因子，需2个以上可用因子参与运算',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.$emit('next',this.divisors.multipleSelection)
    },
    handleBack(){
      this.$emit('back',this.divisors.multipleSelection)
    }
  },
  watch: {
    'currentTarget':{
      handler:function(){
        console.log('DivisorSelect')
        this.init()
      },
      deep:true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
