<template>
  <div class="build" v-loading="loading">
    <SubNav :nav="subNav" :defaultActive="modelFreq" @change="subNavChange"></SubNav>
    <div class="column margin-20" style="min-height:calc(100vh - 175px); margin-bottom: 0;">
      <el-steps :active="stepActive" align-center process-status="finish" finish-status="process">
        <el-step title="目标选择"></el-step>
        <el-step title="因子选择"></el-step>
        <el-step title="关系验证" ></el-step>
        <el-step title="模型运算" ></el-step>
        <el-step title="情景模拟" ></el-step>
      </el-steps>
      <div class="margin-top-20">
        <TargetSelect ref="targetSelect" v-show="stepActive==0" @next="next" :productList="productList" :modelFreq="modelFreq"></TargetSelect>
        <DivisorSelect ref="divisorSelect" v-show="stepActive==1" @next="next" @back="back" :productList="productList" :modelFreq="modelFreq" :currentTarget="currentTarget"></DivisorSelect>
        <RelaValidation ref="relaValidation" v-show="stepActive==2" @next="next" @back="back" :modelFreq="modelFreq" :currentTarget="currentTarget" :divisors="divisors"></RelaValidation>
        <ModelOperation ref="modelOperation" v-show="stepActive==3" @next="next" @back="back" :modelFreq="modelFreq" :currentTarget="currentTarget" :divisors="divisorsOfModel" :creatInfo="creatModelInfo"></ModelOperation>
        <Scene ref="scene" v-show="stepActive==4" @next="next" @back="back" :modelFreq="modelFreq" :currentTarget="currentTarget" :modelInfo="creatModelInfo" :creatInfo="creatSceneInfo" :random="Math.random()"></Scene>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import SubNav from '@/components/SubNav.vue'
import TargetSelect from '@/view/customModel/components/TargetSelect.vue'
import DivisorSelect from '@/view/customModel/components/DivisorSelect.vue'
import RelaValidation from '@/view/customModel/components/RelaValidation.vue'
import ModelOperation from '@/view/customModel/components/ModelOperation.vue'
import Scene from '@/view/customModel/components/Scene.vue'
export default {
  name: 'Build',
  components:{
    SubNav,TargetSelect,DivisorSelect,RelaValidation,ModelOperation,Scene
  },
  props:{
   
  },
  data (){
    return{
      loading:false,
      subNav:[{name:'周度预测模型',value:'week'},{name:'月度预测模型',value:'month'}],
      modelFreq:'week',
      productList:[],
      
      stepActive:0,

      currentTarget:null, 

      divisors:[],

      divisorsOfModel:[],
      creatModelInfo:null,

      creatSceneInfo:null,

    }
  },
  created(){
    this.getProducts()
  },
  mounted(){
   
  },
  destroyed(){
    
  },
 
  methods:{
    getProducts(){
      this.loading=true
      let request=this.$instance.get('/manage/getProductPowerWithCustom',{
          params: {
           ...this.$store.state.basicParams,
           moduleCode:'zi_ding_yi_mo_xing'
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.productList=res.data.info
          this.checkProAuth()
        }else{
          this.$router.replace({
            path:'/error',
            query:{
              redirect:this.$route.fullPath
            }
          })
        }
      })
      request.catch(()=>{
        this.loading=false
        this.$router.replace({
          path:'/error',
          query:{
            redirect:this.$route.fullPath
          }
        })
      })
      return request
    },
    checkProAuth(){
      if(!this.productList.length){
        this.$alert(`您暂无自定义模型的产品权限`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.replace({
            path:'/'
          })
        })
      }
    },
    subNavChange(val){
      this.modelFreq=val
    },
    next(val){
      switch(this.stepActive){
        case 0:
          this.currentTarget=val
        break;
        case 1:
          this.divisors=JSON.parse(JSON.stringify(val))
        break;
        case 2:
          this.divisorsOfModel=val.divisors
          this.creatModelInfo=val.creatInfo
        break;
        case 3:
          this.creatSceneInfo=val
        break;
      }
      this.stepActive+=1
    },
    back(){
      this.stepActive-=1
    }
    

  },
  computed:{
   
  },
  watch: {
    "modelFreq" : {
      handler:function(){
        this.stepActive=0
        this.currentTarget=null

       
      },
      deep:true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>


</style>
<style lang="scss">

</style>
