<template>
  <div>
      <div id="dragMain" class="flex-column">
        <div class="flex-row justify-between border-bottom padding-bottom-10">
          <div class="flex-row align-items-center">
            <div>
              <el-button size="mini" @click="handleBack">上一步</el-button>
            </div>
          </div>
          <div class="flex-row align-items-center">
            <div>
              <el-button size="mini" :disabled="!divisorsData.length" type="primary" :loading="getRunIdLoading" @click="getRunId">运算</el-button>
            </div>
          </div>
        </div>
        <div id="topBox" ref="topBox">
            <div>
              <el-table
              :key="random"
                ref="table"
                :height="divisorsListHeight"
                size="medium"
                :data="divisorsData"
                style="width: 100%;">
                <div slot="empty"><el-empty :image-size="80" description="暂无数据"></el-empty></div>
                <el-table-column
                  min-width="250"
                  fixed="left"
                  prop="divisorName"
                  label="因子"
                >
                  <template slot="header">
                    <span>因子</span>
                    <span class="color-sub font-size-12" style="font-weight: normal;"> （数量：{{divisorsData.length}}）</span>
                  </template>
                  <template slot-scope="props">
                      <div>
                        <span>{{props.row.divisorName}}</span>
                        <span class="color-sub font-size-12 text-nowrap">{{props.row.unit?`（${props.row.unit}）`:''}}</span>
                      </div>
                  </template>
                </el-table-column>
                <template v-if="divisorsData.length">
                  <el-table-column v-for="(item,index) in divisorsData[0].valuesTimesShow" :key="index" min-width="105" :label="modelFreq=='week'?item:$dateFormat(item,'yyyy-MM')" align="center">
                    <template  slot-scope="props">
                      <div style="min-width:95px;width:100%;" :class="{'required':modelFreq=='week'&&!$numFormat(props.row.divisorValues[index].value)&&props.row.divisorValues.slice(index,props.row.divisorValues.length).some(v=>{return $numFormat(v.value)})}">
                        <el-input class="border" :disabled="props.row.divisorValues[index].valueType==1" :placeholder="modelFreq=='week'&&!$numFormat(props.row.divisorValues[index].value)&&props.row.divisorValues.slice(index,props.row.divisorValues.length).some(v=>{return $numFormat(v.value)})?'必填':'输入数值'" clearable
                          v-model.trim="props.row.divisorValues[index].value"
                          size="mini"
                          maxlength="10"
                        ></el-input>
                      </div>
                      
                    </template>
                  </el-table-column>
                  <el-table-column width="50" label="操作" align="center" fixed="right">
                    <template  slot-scope="props">
                      <el-button type="text" size="mini" @click="clearDivisorsValues(props.row)">清空</el-button>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>

        </div>

        <div id="resizeBar"></div>

        <div id="downBox" class="flex-column">
          <div class="flex-auto flex-column" style="padding:20px">
            <div class="flex-row align-items-center justify-between text-nowrap">
              <span class="color-sub font-size-12">预测周期：<template>{{((result||{}).startTime||((creatInfo||{}).result||{}).startTime) | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{((result||{}).endTime||((creatInfo||{}).result||{}).endTime) | dateFormat(this.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template></span>
            </div>
            <Chart :option="forecastChart" emptyText="暂无运算数据" height="100px" class="flex-auto"></Chart>
          </div>
        </div>
      </div>
      <div class="flex-row justify-center border-top padding-top-10">
        <el-button size="mini" type="primary" :disabled="!result" :loading="save.loading"  @click="checkSave">保存</el-button>
      </div>
      <el-dialog custom-class="custom-dialog" title="保存我的模型" :visible.sync="save.visible" width="400px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="35vh">
          <div>
            <el-input v-model.trim="save.title" maxlength="30" show-word-limit placeholder="请为您的模型命名"></el-input>
          </div>
          <div class="flex-row justify-center margin-top-20">
            <el-button size="mini" @click="save.visible=false">取消</el-button>
            <el-button size="mini" type="primary" :loading="save.loading" @click="saveModel(true)">保存</el-button>
          </div>

        </el-dialog>
      <el-dialog
        custom-class="custom-dialog"
        :append-to-body="true"
        top="30vh"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="progress.visible"
        width="40%"
        >
        <div slot="title">
          <div class="font-size-20">模型正在计算，预计需要{{(Math.round(progress.totalTime/60)||1)}}分钟，请稍候...</div>
          <div class="color-sub margin-top-10">温馨提示：刷新或关闭页面会中止运算，并将页面初始化。</div>
        </div>
        <Progress :totalSeconds="progress.totalTime" :finished="progress.finished" v-if="progress.visible" @cancel="cancelOperation"></Progress>
      </el-dialog>

  </div>
</template>

<script>
import Chart from "@/components/Chart";
import Progress from "@/components/Progress";
import progressTotalTime from "@/common/progressTotalTime";//进度条时间计算方法
import { dragTwoRowDiv } from "@/common/dragResize";
export default {
  name: 'Scene',
  components:{
    Chart,
    Progress,
  },
  props:{
    random:{
      type:Number,
      default:0
    },
    modelInfo:{
      type:Object,
      default:()=>{
        return null
      }
    },
    creatInfo:{
      type:Object,
      default:()=>{
        return null
      }
    },
    modelFreq:{
      type:String,
      default:'week'
    },
    currentTarget:{
      type:Object,
      default:()=>{
        return null
      }
    }
  },
  data (){
    return{
      divisorsListHeight:0,//已选因子列表高度
      runTimer:null,//运算轮巡定时器
      getRunIdLoading:false,
      dagRunId:'',
      publishId:'',

      divisorsData:[],
      result:null,
      save:{//保存模型弹窗
        visible:false,
        loading:false,
        title:''
      },
      progress:{
        visible:false,
        finished:true,
        totalTime:60,
      },
      successPublishId:null,
    }
  },
  created(){
    this.getData()
  },
  
  mounted(){
    dragTwoRowDiv("dragMain", "topBox", "resizeBar", "downBox");
    this.$nextTick(()=>{
      this.divisorsListHeight=this.$refs.topBox.clientHeight-10
    })
    this.sizeLinstener();//右侧可拖动容器尺寸变化监听
    
  },
  destroyed(){
   this.killDag()
  },
  
  methods:{
    //初始化数据
    init(){
      this.divisorsData=[]
      this.result=null
      this.getData()
    },
    getData(){
      if(this.creatInfo){
        this.getdivisors(this.creatInfo.publishId)
      }
    },
   //页面尺寸变化监听
    sizeLinstener(){
      this.$erd.listenTo({
          strategy: "scroll", //<- For ultra performance.
          callOnAdd: false,
          debug: false
      },this.$refs.topBox, (dom)=>{
          this.divisorsListHeight=dom.clientHeight-10
      });
    },
    getdivisors(publishId){
      this.$instance.get('/befarCustomModel/getModelDivsiorValue',{
        params:{
          modelFreq:this.modelFreq,
          modelId:this.modelInfo.customModelId,
          publishId:publishId,
        }
      })
      .then(res=>{
        if(res.data.code==0){
          this.divisorsData=(res.data.info||{}).customDivisors||[]
          this.$nextTick(()=>{
            this.$refs.table.doLayout()
          })
        }
      })
      .catch(()=>{

      })
    },
    getRunId(){
      let allow=true
      if(this.modelFreq=='week'){
        for(let item of this.divisorsData){
          if (item.divisorValues.findIndex(v=>!this.$numFormat(v.value))>=0&&item.divisorValues.slice(item.divisorValues.findIndex(v=>!this.$numFormat(v.value)),item.divisorValues.length).some(v=>{return this.$numFormat(v.value)})){
            allow=false
            break;
          }
        }
      }
      if(!allow){
        this.$message({message:'请输入因子必填值',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.getRunIdLoading=true
      let requestData={
        modelFreq:this.modelFreq,
        modelId:this.modelInfo.customModelId,
        divisors:this.divisorsData.map(v=>{return{
          "divisorId": v.divisorId,
          "divisorValues": v.divisorValues.map(vv=>{return vv.value}),
          "divisorValueTypes": v.divisorValues.map(vv=>{return vv.valueType}),
          "months":  v.valuesTimes,
        }})
      }
      this.$instance.post('/befarCustomModel/calScene',requestData)
      .then(res=>{
        this.getRunIdLoading=false
        if(res.data.code==0){
          this.progress.totalTime=progressTotalTime(this.modelFreq=='week'?null:30000,this.divisorsData.length+1)
          this.progress.finished=false
          this.progress.visible=true
          this.dagRunId=res.data.info.dagRunId
          this.publishId=res.data.info.publishId
          this.runModel()
        }
      })
      .catch(()=>{
        this.getRunIdLoading=false
      })
    },
    runModel(){
      this.$instance.get('/befarCustomModel/getTargetCustomValue',{
        params: {
          ...this.$store.state.basicParams,
          dagRunId:this.dagRunId,
          modelFreq:this.modelFreq,
          modelId:this.modelInfo.customModelId,
          publishId:this.publishId,
          type:'cond'
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.result=res.data.info
          this.successPublishId=this.publishId
          this.progress.finished=true
          this.getdivisors(this.publishId)          
          setTimeout(()=>{this.progress.visible=false},1000)

        }else if(res.data.code==1){
          console.log(1)
          this.$message.closeAll()
          this.runTimer=setTimeout(()=>{this.runModel()},3000)
        }else{
          this.progress.finished=true
          this.progress.visible=false
        }
      })
      .catch(()=>{
        this.progress.finished=true
          this.progress.visible=false
      })
    },
    //中止运算
    killDag(){
      !this.progress.finished&&this.dagRunId&&this.cancelOperation()
      !this.progress.finished&&clearTimeout(this.runTimer)
    },
    cancelOperation(){
      this.$instance.get('/befarCustomModel/killDag',{
          params: {
            ...this.$store.state.basicParams,
            dagRunId:this.dagRunId,
            dagId:this.modelFreq=='week'?'befar_pf_custom_condition_week ':'befar_pf_custom_condition_month'
          },
      })
      .then(res=>{
          if(res.data.code==0){
            this.progress.finished=true
            this.progress.visible=false;
            clearTimeout(this.runTimer)
          }
      })
      .catch(()=> {}); 
    },
    //保存模型
    checkSave(){
      this.save.title=''
      this.save.loading=true
      this.$instance.get('/befarCustomModel/checkExistModel',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.modelInfo.customModelId,
          },
      })
      .then(res=>{
        this.save.loading=false
        if(res.data.code==0){
          if(res.data.info){  
            this.saveModel()
          }else{
            this.save.visible=true
          }
        }
      })
      .catch(()=>{
        this.save.loading=false
      })
    },
    saveModel(checkTitle){
      if(checkTitle&&!this.save.title){
        this.$message({message:'请输入模型名称',type:'warning',center:true})
        return false
      }
      this.save.loading=true
      this.$instance.get('/befarCustomModel/modifyModelName',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.modelInfo.customModelId,
           modelName:this.save.title,
           publishId:this.successPublishId
          },
      })
      .then(res=>{
        this.save.loading=false
        if(res.data.code==0){
          this.save.visible=false
          this.$message({message:'保存成功，请前往“结果查看”中查看',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=>{
        this.save.loading=false
      })
    },
    //清空模型因子值
    clearDivisorsValues(row){
      if(row){
        row.divisorValues.forEach(v=>{
            if (v.valueType!=1){
              v.value=''
            }
        })
      }else{
        this.divisorsData.forEach(v=>{
          v.divisorValues.forEach(vv=>{
            if (vv.valueType!=1){
              vv.value=''
            }
          })
        })
      }
      
      
    },
    handleBack(){
      this.$emit('back',null)
    }

  },
  computed:{
    divisorsValueAllEmpty(){
      return this.divisorsData.every(v=>{return v.divisorValues.every(vv=>{return vv.valueType==1?true:!vv.value})})
    },
    //预测结果图表
    forecastChart(){
      let chartData=this.result||(this.creatInfo||{}).result||{}
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=chartData.items[0].unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
  },
  watch: {
    'creatInfo':{
      handler:function(){
        console.log('Scene')
        this.init()
      },
      deep:true,
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

#dragMain {
  width: 100%;
  height: calc(100vh - 295px);
  min-height: 600px;
  overflow: hidden;
  position: relative;
}
#topBox {
  height: calc(55% - 10px);
  overflow: hidden;
  box-sizing: border-box;
}
#resizeBar {
  height: 10px;
  flex:0 0 10px;
  @include themeify{
    background:themed('background');
  };
  cursor: s-resize;
  position: relative;
}

#resizeBar::before,#resizeBar>.tips{
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  width: 80px;
  margin-left:-40px;
  height: 10px;
  @include themeify{
    background:themed('lineColor');
  };
}
#resizeBar::after{
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  width: 40px;
  margin-left:-20px;
  height: 2px;
  margin-top:-2px;
  @include themeify{
    border-top: 1px solid themed('columnBackground');
     border-bottom: 1px solid themed('columnBackground');
  };
}
#downBox {
  height: 45%;
  box-sizing: border-box;
  overflow: hidden;
}

</style>
<style lang="scss">
.required{
  .el-input .el-input__inner{
    border-color: red!important;
  }
}
</style>
