<template>
    <div v-loading="targets.loading">
      <div class="flex-row justify-between border-bottom padding-bottom-10">
        <div class="flex-row">
          <div>
            <el-select v-model="targets.curProdId" @change="changeTargetProd" size="mini">
              <el-option value="" label="全部"></el-option>
              <el-option v-for="(item,index) in productList" :key="index" :value="item.productId" :label="item.productName"></el-option>
            </el-select>
          </div>
          <div class="margin-left-10">
            <el-input v-model.trim="targets.keyWord" size="mini" prefix-icon="el-icon-search" placeholder="请输入关键字" clearable></el-input>
          </div>
        </div>
        <div class="flex-row">
          <div class="border-right padding-right-10">
            <el-button size="mini" @click="addTargetsShow">添加目标</el-button>
          </div>
          <div class="margin-left-10">
            <el-button size="mini" type="primary" @click="handleNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div>
        <el-table
          class="hoverRowTable"
          ref="targetsTable"
          :height="$root.docHeight-295"
          :data="TargetsOfFilter"
          size="medium"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 100%">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column label="产品">
            <template slot-scope="props"><div style="cursor: default;">{{props.row.productName}}</div></template>
          </el-table-column>
          <el-table-column label="预测目标" min-width="200">
            <template slot-scope="props"><div style="cursor: default;">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                  <div><span class="color-sub">数据名称：</span>{{props.row.child.factorName}}</div>
                  <div><span class="color-sub">数据单位：</span>{{props.row.child.unit}}</div>
                  <div><span class="color-sub">数据类型：</span>{{props.row.child.typeName}}</div>
                  <div><span class="color-sub">更新频率：</span>{{props.row.child.modelFreq}}</div>
                  <div><span class="color-sub">统计日期：</span>{{props.row.child.startDate}}{{props.row.child.startDate&&props.row.child.endDate?"至":""}}{{props.row.child.endDate}}</div>
                  <div><span class="color-sub">方法论：</span>{{props.row.child.func}}</div>
                  <div><span class="color-sub">数据来源：</span>{{props.row.child.dataSource}}</div>

                </div>
                <span slot="reference" class="display-inline-block">
                    <span class="link">{{props.row.targetNameAll}}</span>
                </span>
              </el-popover>  
            </div></template>
          </el-table-column>
          <el-table-column label="单位">
            <template slot-scope="props"><div style="cursor: default;">{{props.row.unit}}</div></template>
          </el-table-column>
          <el-table-column label="区域">
            <template slot-scope="props"><div style="cursor: default;">{{props.row.marketAttr}}</div></template>
          </el-table-column>
          <el-table-column label="规格牌号">
            <template slot-scope="props"><div style="cursor: default;">{{props.row.dataModel}}</div></template>
          </el-table-column>
          <el-table-column label="数据周期" width="270">
            <template slot-scope="props">
              <div class="flex-row align-items-center" style="cursor: default;">
                <div :class="{'color-red':!props.row.dataFlag}">{{props.row.startDate}} {{props.row.startDate&&props.row.endDate?"至":""}} {{props.row.endDate}}</div>
                <div v-if="!props.row.dataFlag" class="custom-tag margin-left-5" color="red">数据不足</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog custom-class="custom-dialog" :visible.sync="addTargets.visible" width="80%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="50px">
          <div slot="title">
            <div class="flex-row align-items-center justify-between margin-right-40">
              <div class="el-dialog__title">添加目标</div>
            </div>
          </div>
          <div v-loading="addTargets.loading">
            <div class="flex-row justify-between border-bottom padding-bottom-10">
              <div class="flex-row">
                <div>
                  <el-select v-model="addTargets.curProdId" size="mini" @change="changeAddTargetProd">
                    <el-option v-for="(item,index) in productList" :key="index" :value="item.productId" :label="item.productName"></el-option>
                  </el-select>
                </div>
                <div class="margin-left-10">
                  <el-input v-model="addTargets.keyWord" size="mini" prefix-icon="el-icon-search" placeholder="请输入关键字" clearable></el-input>
                </div>
              </div>
              <div>数据量：{{ addTargetsOfFilter.length }}</div>
            </div>
            <el-table
              :height="$root.docHeight-330"
              :data="addTargetsOfFilter"
              size="medium"
              :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
              @selection-change="handleAddTargetSelection"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column label="产品">
                <template slot-scope="props">{{props.row.productName}}</template>
              </el-table-column>
              <el-table-column label="预测目标" min-width="200">
                <template slot-scope="props">
                  <el-popover placement="right-start" width="350" trigger="hover">
                    <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">

                      <div><span class="color-sub">数据名称：</span>{{props.row.child.factorName}}</div>
                      <div><span class="color-sub">数据单位：</span>{{props.row.child.unit}}</div>
                      <div><span class="color-sub">数据类型：</span>{{props.row.child.typeName}}</div>
                      <div><span class="color-sub">更新频率：</span>{{props.row.child.modelFreq}}</div>
                      <div><span class="color-sub">统计日期：</span>{{props.row.child.startDate}}{{props.row.child.startDate&&props.row.child.endDate?"至":""}}{{props.row.child.endDate}}</div>
                      <div><span class="color-sub">方法论：</span>{{props.row.child.func}}</div>
                      <div><span class="color-sub">数据来源：</span>{{props.row.child.dataSource}}</div>

                    </div>
                    <span slot="reference" class="display-inline-block">
                        <span class="link">{{props.row.targetNameAll}}</span>
                    </span>
                  </el-popover>  
                </template>
              </el-table-column>
              <el-table-column label="区域">
                <template slot-scope="props">{{props.row.marketAttr}}</template>
              </el-table-column>
              <el-table-column label="规格牌号">
                <template slot-scope="props">{{props.row.dataModel}}</template>
              </el-table-column>
              <el-table-column label="数据周期" width="200">
                <template slot-scope="props">{{props.row.startDate}} {{props.row.startDate&&props.row.endDate?"至":""}} {{props.row.endDate}}</template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <div class="flex-row justify-end">
              <el-button size="small" @click="addTargets.visible=false">取消</el-button>
              <el-button size="" type="primary" :loading="addTargets.loading" @click="addTargetsSubmit">确定</el-button>
            </div>
          </div>
        </el-dialog>
    </div> 
</template>

<script>
export default {
  name: 'TargetSelect',
  components: {  },
  props:{
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    modelFreq:{
      type:String,
      default:'week'
    }
  },
  data() {
    return {      
      targets:{
        loading:false,
        list:[],
        curProdId:'',
        keyWord:'',
        currentRow:null,
      },
      addTargets:{
        loading:false,
        visible:false,
        list:[],
        curProdId:'',
        keyWord:'',
        multipleSelection:[],
      }
    }
  },
  computed:{
    TargetsOfFilter(){
      return this.targets.list.filter(v=>{return (!this.targets.curProdId||v.productId==this.targets.curProdId)&&v.targetNameAll.indexOf(this.targets.keyWord)>=0})
    },
    addTargetsOfFilter(){
      return this.addTargets.list.filter(v=>{return v.targetNameAll.indexOf(this.addTargets.keyWord)>=0})
    }
  },
  created(){
    this.getData()
  },
  methods:{
    init(){
      this.targets.curProdId=''
      this.targets.keyWord=''
      this.$refs.targetsTable.setCurrentRow()
      this.getData()
    },
    getData(){
      if(this.productList.length){
        this.getTargetList()
      }
    },
    //获取目标列表
    getTargetList(){
      this.targets.loading=true
      this.$instance.get('/befarCustomModel/getTargetList',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.targets.loading=false
        if(res.data.code==0){
          this.targets.list=res.data.info
        }else{
          this.targets.list=[]
        }
      })
      .catch(()=>{
        this.targets.loading=false
        this.targets.list=[]
      })
    },
    changeTargetProd(){
      this.targets.keyWord=''
    },
    //选择目标
    handleCurrentChange(val) {
      this.targets.currentRow = val;
    },
    //添加目标弹窗显示
    addTargetsShow(){
      this.addTargets.visible=true
      !this.addTargets.curProdId&&(this.addTargets.curProdId=(this.productList[0]||{}).productId)
      this.addTargets.keyWord=''
      this.addTargets.curProdId&&this.getAddTargetList()
    },
    //添加目标列表切换产品
    changeAddTargetProd(){
      this.addTargets.keyWord=''
      this.getAddTargetList()
    },
    //获取添加目标列表
    getAddTargetList(){
      this.addTargets.loading=true
      this.$instance.get('/befarCustomModel/getAddTargetList',{
          params: {
           ...this.$store.state.basicParams,
           productId:this.addTargets.curProdId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.addTargets.loading=false
        if(res.data.code==0){
          this.addTargets.list=res.data.info
        }else{
          this.addTargets.list=[]
        }
      })
      .catch(()=>{
        this.addTargets.loading=false
        this.addTargets.list=[]
      })
    },
    //勾选添加目标列表
    handleAddTargetSelection(val) {
      this.addTargets.multipleSelection = val;
    },
    addTargetsSubmit(){
      if(!this.addTargets.multipleSelection.length){
        this.$message({message:'请选择要添加的目标',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      this.addTargets.loading=true
      let requestData=this.addTargets.multipleSelection.map(v=>{return {
        modelFreq:this.modelFreq,
        productId:this.addTargets.curProdId,
        isTarget:v.isTarget,
        dataId:v.dataId,
        targetNameAll:v.targetNameAll,
        unit:v.unit,
        marketAttr:v.marketAttr,
        dataModel:v.dataModel,
        type:v.type,
        startDate:v.startDate,
        endDate:v.endDate,
        typeId:v.typeId||'',
      }})
      this.$instance.post('/befarCustomModel/addTarget',requestData)
      .then(res=>{
        this.addTargets.loading=false
        if(res.data.code==0){
          this.getData()
          this.addTargets.visible=false;
          this.$message({message:'添加成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=>{
        this.addTargets.loading=false
      })
    },
    //下一步
    handleNext(){
      if(!this.targets.currentRow){
        this.$message({message:'请选择预测目标',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }else if(!this.targets.currentRow.dataFlag){
        this.$message({message:'预测目标数据不足，请更换目标',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      this.$emit('next',this.targets.currentRow)
    }
  },
  watch: {
    'productList':{
      handler:function(){
        this.getData()
      },
      deep:true,
    },
    'modelFreq':{
      handler:function(){
        this.init()
      },
      deep:true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
